
  * {
    box-sizing: border-box;
  }
  
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  input[type="range"] {
    height: 5px;
    -webkit-appearance: none;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: #fff 0.2s ease;
    cursor: pointer;
  }
  
  .audio-player {
    max-width: 500px;
    padding: 24px;
    border-radius: 20px;
    margin: auto;
    color: #727272;
  }

  .track-info {
    text-align: center;
    z-index: 1;
    position: relative;
  }
  
  .audio-controls {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto 40px;
  }
  
  .audio-controls .play svg,
  .audio-controls .pause svg {
    height: 30px;
    width: 30px;
  }
  
  .audio-controls path {
    fill: #ffffff;
  }
  
  @keyframes colorChange {
    from {
      filter: hue-rotate(0deg);
    }
    to {
      filter: hue-rotate(360deg);
    }
  }
  