.feelingWrapper{
    position: relative;
}

.feelingIcon{
    text-align: center;
    font-size: 2.2em;
}

.feelingCount{
    text-align: center;
    font-size: .65em;
}