.main-body {
    background-image: linear-gradient(#faf6f4, #FFFFFF);
}

.hero {
    padding-top: 100px;
    height: auto;
    padding-bottom: 100px;
}

.heroText{
    font-weight: 600;
    font-size: 2.4em;
    line-height: 1.1em;
    padding: 75px 5px 0px;
    color: rgb(0, 0, 0);
}
.heroSubText{
    font-weight: 400;
    font-size: 1em;
    line-height: 1em;
    margin: 0px;
    padding: 0px 0px 10px;
    color: rgb(0, 0, 0);
}
.heroSubSubText{
    font-weight: 200;
    font-size: 0.7em;
    line-height: 0.9em;
    padding: 0px 0px 0px;
    color: rgb(0, 0, 0);
}

.heroButton{
    width: 140px;
    padding: 5px;
    border-radius: 25px;
    margin-bottom: 75px;
}

.openingText{
    font-size: 1.2em;
}

.messageHeader{
    padding-top: 10px;
    font-weight: 600;
    font-size: 1.1em;
    margin: 0px;
}

p {
    font-size: 1em;
}


@media (min-width: 768px){

    .heroText{
        font-weight: 600;
        font-size: 3.2em;
        line-height: .9em;
        padding: 75px 50px 0px;
        color: rgb(0, 0, 0);
    }

}







body {
    color: #020202;
    font-weight: 300;
    font-style: normal;
    font-size: .8em;
}

h1{
    font-size: 2.7em;
    font-weight: 600;
    line-height: 1.1;
    letter-spacing: -1.35px;
}
h2 {
    font-size: 1.3em;
    font-weight: 300;
}
h3 {
    font-size: 2em;
    font-weight: 300;
    letter-spacing: -0.8px;
}
h4 {
    font-size: 1.2em;
    font-weight: 300;
    line-height: 1.3;
    color: rgb(65, 65, 65);
    letter-spacing: -0.6px;
}

.form-control {
    font-size: 1em;
}

.text-wrapper{
    position: relative;
}

.header{
    position: relative;
    padding: 10px;
    font-size: 11px;
    width: 240px;
    opacity: 0;
    color: rgb(65, 65, 65);
    z-index: 10;
}

.verseMiddle{
    position: relative;
    padding: 15px;
    font-size: 13px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: #dfdfdf;
    color: #000;
    width: 240px;
    overflow: hidden;
    z-index: 10;
}

.verseStart{
    position: absolute;
    padding: 15px;
    font-size: 13px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: #dfdfdf;
    color: #000;
    width: 240px;
    rotate: -20deg;
    left: 0%;
}
.verseEnd{
    position: absolute;
    padding: 15px;
    font-size: 13px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: #dfdfdf;
    color: #000;
    width: 240px;
    right: 0%;
    rotate: 20deg;
}


@media (min-width: 700px){

    body {
        color: #020202;
        font-weight: 300;
        font-style: normal;
        font-size: .9em;
    }

    .hero {
        padding-top: 150px;
        height: auto;
        padding-bottom: 150px;
    }

    h1 {
        font-size: 3.7em;
        font-weight: 500;
        line-height: 1;
    }
    h2 {
        font-size: 2.0em;
        font-weight: 300;
    }
    h3 {
        font-size: 2.5em;
        font-weight: 300;
    }
    h4 {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.4;
        color: rgb(90, 90, 90);
    }
}