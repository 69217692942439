.commentWrap{
    width: 100%;
    border-bottom: solid 0.75px rgb(233, 233, 233);
    margin-top: 20px;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 0px;
}

.left {
    float: left;
    height: 100%;
}

.right{
    margin-left: 60px
}
.userPhoto{
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: lavender;
    overflow: hidden;
    border: solid 0.25px rgb(231, 231, 231);
}

.userPhoto img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

.userName{
    font-size: 0.9em;
    font-weight: 400;
}

.userComment{
    color: rgb(83, 83, 83);
    padding: 0px 0px;
    white-space: pre-line;
    font-size: 0.9em;
}

.commentTimestamp{
    margin-left: 5px;
    font-size: 0.8em;
    font-weight: 400;
    color: rgb(146, 146, 146);
}
.commentActions{
    float: right;
    top: 0px;
}