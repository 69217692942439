
    .msgrChat {
        margin-bottom: 130px;
    }
    .msg {
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    }
    
    .msg-bubble {
    max-width: 80%;
    min-width: 30%;
    padding: 18px;
    margin-bottom: 0px;
    background: var(--left-msg-bg);
    border-radius: 10px;
    }

    .msg-bubble p {
        margin: 0px;
    }
    
    .left-msg .msg-bubble {
    border-bottom-left-radius: 0;
    }
    
    .prompt-msg {
    flex-direction: row-reverse;
    }
    .prompt-msg .msg-bubble {
    background: var(--prompt-msg-bg);
    color: rgb(161, 161, 161);
    border-bottom-right-radius: 0;
    }
    
    
    .right-msg {
    flex-direction: row-reverse;
    }
    .right-msg .msg-bubble {
    background: var(--right-msg-bg);
    color: #fff;
    border-bottom-right-radius: 0;
    }
    
    
    .msger-inputarea {
    display: flex;
    position: relative;
    padding: 10px;
    background: rgb(255, 255, 255);
    }
    .msger-inputarea * {
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    }
    .msger-input {
    flex: 1;
    padding: 20px;
    background: rgb(243, 243, 243);
    }
    .msger-send-btn {
    margin-left: 10px;
    width: 80px;
    background: rgb(90, 90, 90);
    cursor: pointer;
    text-align:center;
    font-size: 1em;
    color: white;
    }
    .msger-send-btn:hover {
    background: rgb(156, 156, 156);
    }

    
    
    .promptBtn1 {
        background-color: rgb(236, 242, 255);
        border: solid 1px rgba(173, 185, 211);
        color:rgb(78, 71, 58);
        font-weight: 300;
        margin-left: 0px;
        height: 50px;
        animation-duration: .75s;
        transition-timing-function: ease;
        animation-name: slidein;
        will-change: transform;
    }
    .promptBtn_2 {
        background-color: rgb(236, 242, 255);
        border: solid 1px rgba(173, 185, 211);
        color:rgb(78, 71, 58);
        font-weight: 300;
        margin-left: 0px;
        height: 50px;
        animation-delay: .15s;
        animation-duration: .88s;
        transition-timing-function: ease-out;
        animation-name: slidein;
        will-change: transform;
    }
    .promptBtn3 {
        background-color: rgb(236, 242, 255);
        border: solid 1px rgba(173, 185, 211);
        color:rgb(78, 71, 58);
        font-weight: 300;
        margin-left: 0px;
        height: 50px;
        animation-delay: .21s;
        animation-duration: 1s;
        transition-timing-function: ease-out;
        animation-name: slidein;
        will-change: transform;
    }
    
    .promptSectionChat {
        display: flex;
        gap: 20px;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        box-shadow: 0 3px 10px 0 rgba(#000, 0.1);
        -webkit-overflow-scrolling: touch;
        scroll-padding: 1rem;
        padding: 0rem 1rem;
        margin: 0 auto 0;
        border-radius: 5px;
        animation-duration: .75s;
        transition-timing-function: ease-in-out;
        animation-name: slidein;
        will-change: transform;
      }
      
      .promptItemChat {
        display: flex;
        font-weight: 300;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        flex: 0 0 auto;
        padding: 20px;
        border-radius: 10px;
        height: 40px;
        background-color: rgb(236, 242, 255);
        border: solid 1px rgba(173, 185, 211);
        color:rgb(78, 71, 58);
        scroll-snap-align: center;
        scroll-snap-stop: always;
        margin-left: 0px;
      }
    
      .prompSectionSide ul li{
        list-style-type: none;
        text-decoration: none;
        padding-top: 15px;
      }
    
      .sidePrompt {
            font-weight: 400;
            color: rgb(0, 0, 0);
            text-decoration: none;
      }
    
    @keyframes slidein {
        0% {
            overflow-x: hidden;
            margin-left: 400%;
            opacity: 0;
        }
    
        100% {
            overflow-x: hidden;
            margin-left: 0%;
            opacity: 1;
        }
    }




    
    @media (max-width: 768px){
        
        .promptWrapperChat {
            margin: 0;
            display: flex;
            justify-content: space-between;
            padding-top: 10px;
        }

        .chatHolder {
            width: 100%;
            padding: 0px;
            margin: 0px;
        }

        .inputWrapperChat {
            position: fixed;
            bottom: 0px;
            right: 0;
            width: 100%;
            background-color: #ffffff;
            border-top: solid 1px #ccc;
        }
        
    }
    
    @media (min-width: 768px){
        
        .promptWrapperChat {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding-top: 10px;
        }
        
        .chatHolder {
            width: 50%;
        }

        .inputWrapperChat {
            position: fixed;
            bottom: 0px;
            right: 0;
            width: 50%;
            background-color: #ffffff;
            border-top: solid 1px #ccc;
        }

    }
    