.login-body {
  background-color: rgb(255, 255, 255);
  height: 100vh;
}
.login-container {
  margin-top: 15vh;
  text-align: center;
  background-color: #f3f3f3;
  border-radius: 20px;
  font-size: 16px;
}
.login-btn {
  width: 100%;
  padding: 16px;
  font-size: 17px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: rgb(71, 71, 71);
}