.resources {
  display: flex;
  gap: 18px;
  max-width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 1rem;
  padding: 1.1rem 1rem;
  margin: 0 auto 0;
  margin-bottom: 0.9em;
  animation-duration: .40s;
  transition-timing-function: ease-in-out;
  animation-name: slidein;
  will-change: transform;
  z-index: 1;
}

.resourceItem {
  display: flex;
  font-weight: 300;
  font-size: 0.7em;
  line-height: 1em;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  flex: 0 0 auto;
  width: 120px;
  height: 90px;
  padding: 1px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  border: solid 1px rgba(173, 185, 211);
  color:rgb(78, 71, 58);
  scroll-snap-align: center;
  scroll-snap-stop: always;
  margin-left: 0px;
  z-index: 1;
  overflow: hidden;
}
.resourceItem img{
  /*border-radius: 35px;*/
  width: 125%;
  height: 125%;
  object-fit: contain;
}


.audioWrapper {
  margin-top: 20px;
  max-width: 100%;
  z-index: 1;
}
.audioMessage{
  float: left;
  clear: right;
  display: flex;
  font-weight: 300;
  font-size: 0.7em;
  line-height: 1em;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 60px;
  height: 60px;
  padding: 1px;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  border: solid 2px rgba(173, 185, 211);
  color:rgb(78, 71, 58);
  z-index: 1;
  overflow: hidden;
}
.audioMessage img{
  border-radius: 35px;
  width: 125%;
  height: 125%;
  object-fit: contain;
}


/*
.resourceItem {
  display: flex;
  font-weight: 300;
  font-size: 0.7em;
  line-height: 1em;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  padding: 1px;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  border: solid 2px rgba(173, 185, 211);
  color:rgb(78, 71, 58);
  scroll-snap-align: center;
  scroll-snap-stop: always;
  margin-left: 0px;
  z-index: 1;
  overflow: hidden;
}




.addAudio {
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  font-size: 1.1em;
  border-radius: 20px;
  background-color: rgb(221, 221, 221);
  border: solid 2px rgb(172, 172, 172);
  color:rgb(102, 102, 101);
  scroll-snap-align: center;
  scroll-snap-stop: always;
  margin-top: 10px;
  margin-left: 0px;
  z-index: 1;
}
*/

.recordAudio {
  display: flex;
  justify-content: center;
  font-weight: 400;
  align-items: center;
  margin-top: 20px;
  width: 80px;
  height: 80px;
  font-size: 1em;
  border-radius: 40px;
  background-color: rgb(241, 64, 64);
  color:rgb(255, 255, 255);
}

.hashtag{
  color:rgb(99, 137, 218);
  margin-left: 10px;
  text-decoration: none;
}