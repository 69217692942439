.promptWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.promptBtn1 {
    background-color: rgb(236, 242, 255);
    border: solid 1px rgba(173, 185, 211);
    color:rgb(78, 71, 58);
    font-weight: 300;
    margin-left: 0px;
    height: 50px;
    animation-duration: .75s;
    transition-timing-function: ease;
    animation-name: slidein;
    will-change: transform;
}
.promptBtn_2 {
    background-color: rgb(236, 242, 255);
    border: solid 1px rgba(173, 185, 211);
    color:rgb(78, 71, 58);
    font-weight: 300;
    margin-left: 0px;
    height: 50px;
    animation-delay: .15s;
    animation-duration: .88s;
    transition-timing-function: ease-out;
    animation-name: slidein;
    will-change: transform;
}
.promptBtn3 {
    background-color: rgb(236, 242, 255);
    border: solid 1px rgba(173, 185, 211);
    color:rgb(78, 71, 58);
    font-weight: 300;
    margin-left: 0px;
    height: 50px;
    animation-delay: .21s;
    animation-duration: 1s;
    transition-timing-function: ease-out;
    animation-name: slidein;
    will-change: transform;
}

.promptSection {
    display: flex;
    gap: 20px;
    max-width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    box-shadow: 0 3px 10px 0 rgba(#000, 0.1);
    -webkit-overflow-scrolling: touch;
    scroll-padding: 1rem;
    padding: 0rem 1rem;
    margin: 0 auto 0;
    border-radius: 5px;
    animation-duration: .75s;
    transition-timing-function: ease-in-out;
    animation-name: slidein;
    will-change: transform;
  }
  
  .promptItem {
    display: flex;
    font-weight: 300;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    flex: 0 0 auto;
    padding: 20px;
    border-radius: 10px;
    height: 40px;
    background-color: rgb(236, 242, 255);
    border: solid 1px rgba(173, 185, 211);
    color:rgb(78, 71, 58);
    scroll-snap-align: center;
    scroll-snap-stop: always;
    margin-left: 0px;
  }

  .prompSectionSide ul li{
    list-style-type: none;
    text-decoration: none;
    padding-top: 15px;
  }

  .sidePrompt {
        font-weight: 400;
        color: rgb(0, 0, 0);
        text-decoration: none;
  }

@keyframes slidein {
    0% {
        overflow-x: hidden;
        margin-left: 400%;
        opacity: 0;
    }

    100% {
        overflow-x: hidden;
        margin-left: 0%;
        opacity: 1;
    }
}

@media (min-width: 768px){
    
    .promptWrapper {
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
    }
    
}

@media (min-width: 1200px){
    
    .promptWrapper {
        width: 50%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
    }
    
}

