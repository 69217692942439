body {
    background-color: #ffffff;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  :root {
  --body-bg: #ffffff 100%;
  --msger-bg: #fff;
  --border: 1px solid #ddd;
  --left-msg-bg: #f1f1f1;
  --prompt-msg-bg: #ffffff;
  --right-msg-bg: #579ffb;
  }
  
  html {
  box-sizing: border-box;
  }
  
  *,
  *:before,
  *:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  }



.leftNav {
    padding-top: 25px;
    padding-left: 15px;
    width: 300px;
    height: auto;
    float: left;
    color:rgb(117, 117, 117);
    background-color: rgb(255, 255, 255);
}


    .msger {
        height: auto;
        flex-flow: column wrap;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 100px;
    }
    
    .msger-chat {

    padding: 0px;
    z-index: 0;
    }
    .msger-chat::-webkit-scrollbar {
    position: absolute;
    width: 4px;
    right: -55px;
    }
    .msger-chat::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    }
    .msger-chat::-webkit-scrollbar-thumb {
    background: #dfdfdf;
    }
    .msg {
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    }
    .msg:last-of-type {
    margin: 0;
    }
    
    .msg-bubble {
    max-width: 60%;
    min-width: 30%;
    padding: 18px;
    margin-bottom: 0px;
    background: var(--left-msg-bg);
    border-radius: 10px;
    }

    .msg-bubble p {
        margin: 0px;
    }
    
    .left-msg .msg-bubble {
    border-bottom-left-radius: 0;
    }
    
    .prompt-msg {
    flex-direction: row-reverse;
    }
    .prompt-msg .msg-bubble {
    background: var(--prompt-msg-bg);
    color: rgb(161, 161, 161);
    border-bottom-right-radius: 0;
    }
    
    
    .right-msg {
    flex-direction: row-reverse;
    }
    .right-msg .msg-bubble {
    background: var(--right-msg-bg);
    color: #fff;
    border-bottom-right-radius: 0;
    }
    
    
    .msger-inputarea {
    display: flex;
    height: 80px;
    position: relative;
    padding: 10px;
    width: 100%;
    background: rgb(255, 255, 255);
    }
    .msger-inputarea * {
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    }
    .msger-input {
    flex: 1;
    padding: 20px;
    background: rgb(243, 243, 243);
    }
    .msger-send-btn {
    margin-left: 10px;
    width: 80px;
    background: rgb(90, 90, 90);
    cursor: pointer;
    text-align:center;
    font-size: 1em;
    color: white;
    }
    .msger-send-btn:hover {
    background: rgb(156, 156, 156);
    }
    
    .msger-chat {
    background-color: #ffffff;
    }

    .msg-action {
        margin-left: 5%;
        width: 40px;
        height: 40px;
        padding-top: 4px;
        text-align: center;
        background-color: rgb(247, 247, 247);
        border-radius: 30px;
    }

    .msg-action a {
        position: relative;
        font-size: 20px;
        color:rgb(216, 216, 216);
    }
    .msg-action a:hover{
        position: relative;
        font-size: 20px;
        color:rgb(126, 126, 126);
    }
    
    .steps{
        padding-left: 10px;
        font: 0.8em;
        font-weight: 300;
        color:rgb(175, 175, 175);
        display: inline-block;
        white-space: nowrap;
  	    overflow: hidden;
        box-sizing: border-box;
        animation: typing 2s steps(50, end) infinite;
    }

    @keyframes typing {
        from { 
            width: 0% 
        }
        to { 
            width: 100% 
        }
    }
    
    
    @keyframes spin {
        0% {
            margin-right: 40px;
            opacity: 0;
        }
        10% {
            margin-right: 40px;
            opacity: 100;
        }
        20% {
            margin-right: 40px;
            opacity: 100;
        }
        30% {
            margin-right: 40px;
            opacity: 0;
        }
        40% {
            margin-right: 30px;
            opacity: 0;
        }
        50% {
            margin-right: 30px;
            opacity: 100;
        }
        60% {
            margin-right: 30px;
            opacity: 100;
        }
        70% {
            margin-right: 30px;
            opacity: 0;
        }
        80% {
            margin-right: 20px;
            opacity: 0;
        }
        90% {
            margin-right: 20px;
            opacity: 100;
        }
        100% {
            margin-right: 20px;
            opacity: 100;
        }
    }


    @media (max-width: 990px){
    
        .msg-bubble {
        max-width: 80%;
        min-width: 40%;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 0px;
        background: var(--left-msg-bg);
        }
    
        .inputWrapper {
        position: fixed;
        bottom: 0px;
        left: 0;
        right: 0;
        min-width: 100%;
        background-color: #ffffff;
        border-top: solid 1px #ccc;
        }

        .msger {
            height: auto;
            margin-bottom: 200px;
            flex-flow: column wrap;
            justify-content: space-between;
            width: 100%;
            overflow-y: scroll;
        }

        .wrapper {
            padding: 10px;
            height: calc(100vh);
            overflow-y: scroll;
        }

    }


    
    
    @media (min-width: 990px){
    
        .inputWrapper {
            position: fixed;
            bottom: 0px;
            width: auto;
            left: 300px;
            right: 0px;
            height: 80px;
            border-top: solid 1px #ccc;
        } 
    
        .prompt-msg .msg-bubble {
            padding-right: 50px;
        }

        .wrapper {
            padding: 10px;
            height: calc(100vh);
            overflow-y: scroll;
        }
        
    }
    




    /*BUBBLE//*/
  .chatWrapper {
    position: fixed;
    color: rgb(255, 255, 255);
    position: -webkit - fixed;
    text-align: center;
    padding-top: 10px;
    font-size: 1.6em;
    bottom: 12px;
    right: 12px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: rgb(101, 147, 199);
  }

  .chatWrapper:hover{
    color: rgb(255, 255, 255);
  }
